<template>
  <InputField
    :id="id"
    type="text"
    :placeholder="placeholder"
    :invalid-feedback="invalidFeedback"
    :value="modelValue"
    @update:model-value="handleInput"
    @keydown="validateLength"
  />
</template>

<script>
import InputField from "@/v3components/shared/Form/InputField.vue"
import { computed } from "vue"

export default {
  name: "StrictInputField",
  components: {
    InputField
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    invalidFeedback: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    maxLength: {
      type: Number,
      default: 30
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const modelValue = computed(() =>
      props?.value?.slice(0, Number(props.maxLength))
    )

    const handleInput = (value) => {
      emit("update:modelValue", value?.slice(0, Number(props.maxLength)))
    }

    const validateLength = (event) => {
      const input = event.target.value
      const inputLength = Number(input.length)
      const excludedKeys = [8, 37, 39, 46]
      if (
        inputLength >= Number(props.maxLength) &&
        !excludedKeys.includes(event.keyCode)
      ) {
        event.preventDefault()
      }
    }

    return {
      modelValue,
      handleInput,
      validateLength
    }
  }
}
</script>
